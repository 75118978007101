
          @import "@/assets/scss/variables.scss";
          @import "@/assets/scss/style.scss";
        
// @import 'perfect-scrollbar/css/perfect-scrollbar.css';
// @import 'vue-multiselect/dist/vue-multiselect.min.css';
@import '../fonts/pretendard.css';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
@import url('https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css');
@import url('//fonts.googleapis.com/earlyaccess/notosanskr.css');

:root {
    --theme-input-radius: 7px;
    --theme-background-color: #F8F7EF;
    --theme-primary-color: #333;
    --theme-secondary-color: #d6bdb8;
    --theme-tertiary-color: #2956db;
    --theme-success-color: #29db41;
    --theme-danger-color: #f83e47;
    --theme-warning-color: #f9a11d;
    --theme-info-color: #2994db;
    --theme-light-color: #bbb;
    --theme-dark-color: #1E1E1E
}

/* reset */
html, body{height:100%; width: 100%; font-size:10px; letter-spacing:-0.5px; color:#000;font-family:$font-stack; scroll-behavior: smooth;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button{margin:0;padding:0;font-family:$font-stack;}
fieldset,img{border:0 none}
dl,ul,ol,menu,li{list-style:none}
blockquote, q{quotes:none}
input,select,textarea,button{vertical-align:middle}
button{border:0 none;background-color:transparent;cursor:pointer}
body{-webkit-text-size-adjust:none} /* 뷰표트 변환시 폰트크기 자동확대 방지 */
table{border-collapse:collapse;border-spacing:0}
body,th,td,input,select,textarea,button{font-size:14px;line-height:1.5;font-family:$font-stack;color:#000}
input[type='text'],input[type='password'],input[type='submit'],input[type='search']{-webkit-appearance:none;}
input:checked[type='checkbox']{background-color:#666;-webkit-appearance:checkbox}
button,input[type='button'],input[type='submit'],input[type='reset'],input[type='file']{-webkit-appearance:button;border-radius:0}
button{outline:none}
input[type='search']::-webkit-search-cancel-button{-webkit-appearance:none}
a{color:#242424;text-decoration:none}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}
em,strong{font-weight:normal;font-style:normal}
small{font-size:initial}
iframe{border:0 none;}


html, body{
    background: var(--yellow-10);
    // touch-action: none;
    touch-action: manipulation;
}

.wrap{
    padding:0;
    // padding:0 20px;
    box-sizing: border-box;
}
.fullscreen {
    width: 100%;
}
.container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height: 100%;
    box-sizing: border-box;
    box-sizing: border-box;
}


.height_100vh {
    height: calc(var(--vh, 1vh) * 100) !important;
}


//모바일 대응
.mobile-padding{
    padding:0 20px;
    box-sizing: border-box;
}
@media only screen and (min-width: 640px) {
    .mobile-padding{
        // 가로가 640 보다 큰 기기에서는 패딩 삭제
        padding:0;
    }
}




/* selectbox */
.selectbox-type01 {
    height: 50px;
  
    &.selectbox select {
      background-color: #fff;
      border: 1px solid var(--yellow-20);
      border-radius: 4px;
      height:100%;
      &.no-value{
        color:#8F8F8F;
      }
  
      &[disabled] {
        opacity: 1;
        border: 1px solid #ECEBE3;
        background-color: var(--yellow-20);
        color: var(--gray-40)
      }
    }
  }