
          @import "@/assets/scss/variables.scss";
          @import "@/assets/scss/style.scss";
        

.tab {
  &:last-of-type {
    margin-bottom: 10px;
  }
  box-sizing: border-box;
  // margin: 10px 10px 0 10px;
  border: 1px solid #ddd;
  border-radius:0 0 5px 5px;
  overflow: hidden;
  .tab-content{
    flex-grow: 1;
    background: #fcfcfc77;
    border-top: 0;
    // padding: 5px;
    min-height: 80px;
    box-sizing: border-box;
    overflow: auto;
  }

  .groups {
    list-style-type: none;
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    .tab-button {
      width:100%;
      min-width:150px;
      font-weight:600;
      .tab-label {
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        display: block;
        margin:0;
        // background: #f0edeeaa;
        background: #F6F7F9;
        border-top: 6px solid #f0edeeaa;
        padding-bottom: 5px;
      }
      .active {
        background: #fcfcfc77;
        border-top: 6px solid var(--theme-primary-color);
        &::after{
          content: '';
          width: 100%;
          height: 2px;
          background: #000;
          display: block;
          position: absolute;
          left:0;
          bottom: -1px;
        }
      }
    }
  }

  &.not-stretch {
    border: none;
    .groups {
      overflow: visible;
      border-bottom: 1px solid #dfded8;
      .tab-button {
        width: auto;
        min-width: auto;
        label {
          position: relative;
          padding: 0 4px;
          height: 30px;
          color: #A0A0A0;
          line-height: 30px;
          border-top: 0;
          background-color: transparent;
          &.active {
            color: var(--gray-100);
            //&:after {
            //  display: block;
            //  position: absolute;
            //  left: 0;
            //  bottom: -2px;
            //  width: 100%;
            //  height: 2px;
            //  background-color: #000;
            //  content: "";
            //}
          }
        }
        &:nth-child(n+2) {
          margin-left: 20px;
        }
      }
    }
  }
}
.vertical {
  border-radius:0 5px 5px 0;
  .groups .tab-button {
    .tab-label {
      // height: 30px;
      // line-height: 30px;
      border-left: 6px solid #f0edeeaa;
      border-top: 0;
    }
    .active {
      border-top: 0;
      background: #fcfcfc77;
      border-left: 6px solid var(--theme-primary-color);
    }
  }

}
.flex {
  display: flex;
}

