
          @import "@/assets/scss/variables.scss";
          @import "@/assets/scss/style.scss";
        

.container{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: 999999;

  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100%;
  box-sizing: border-box;
  background: var(--yellow-10);
  .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 375px;
    height: 100%;
    h1{
        margin-bottom:40px;
        font-size: 0;
    }
    .big-text{
      margin-bottom:10px;
      color: #000;
      font-size: 22px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
    }
    .sub-text{
      margin-bottom:24px;
      color: #696969;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: -0.2px;
      white-space: pre-line;
    }
    .maintenance-notice {
      color: var(--gray-100);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 23px;
    }
  }
}
.button-group{
  /deep/ button{
    width:166px;
  }
}

.footer-text{
  padding-top:20px;
  margin:auto 0 56px 0;
  color: var(--gray-40, #A5A5A5);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

@media only screen and (min-width: 640px) {
  //가로값이 640이하에서 적용
  .button-group{
    /deep/ button{
      width:200px;
    }
  }
}
