// colors
// $primary-color: #3498db;
// $secondary-color: #2ecc71;
// $font-color: #333;
// $background-color: #f5f5f5;

// fonts
$font-stack: "Pretendard",Pretendard;
// $base-font-size: 160px;

// spacing
// $base-spacing: 10px;


:root {
--vh: 100%;



--primary: #F37324;
--white: #fff;
--coral: #CF2027;
--sand: #FACD3C;
--sky: #4C79D3;
--grass: #32B478;


--table-bg:rgba(241, 240, 232, 0.30);
--table-a1:#DDDBD4;
--table-a2:#F49A60;

--yellow-10: #F8F7EF;
--yellow-20: #E8E7E3;
--yellow-30: #E8E6D6;


--gray-10: #F0F0F0;
--gray-20: #E3E3E3;
--gray-30: #bcbcbc;
--gray-40: #A5A5A5;
--gray-50: #8F8F8F;
--gray-60: #787878;
--gray-70: #626262;
--gray-80: #4B4B4B;
--gray-90: #353535;
--gray-100: #1E1E1E;



--disable-font: #A4A4A4;
--disable-bg: #E3E3E3;


}