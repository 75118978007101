
          @import "@/assets/scss/variables.scss";
          @import "@/assets/scss/style.scss";
        

.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 20px;
  box-sizing: border-box;
}

.wrap-input {
  position: relative;
  margin: 0 auto;
  // width: calc(100% - 6px);
  width: 100%;
  box-sizing: border-box;
}

input,
.file-input {
  box-sizing: border-box;
  line-height: 48px;
  text-indent: 10px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.2px;
  width: 100%;
  height: 50px;
  font-family: inherit;
  // color:#8F8F8F;
  color: var(--gray-100);
  border: 1px solid #ecebe3;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:disabled {
    border: 1px solid #ecebe3;
    background-color: #ECEBE3;
    color: var(--gray-40);
  }

  &.plaintext {
    padding: 4px 0;
    border-color: transparent;

    &:disabled {
      background-color: transparent;
    }
  }

  &.file-input {
    width: 100%;
    height: 100%;
    background: #fff url("@/assets/icon/icon_input_file.svg") no-repeat
      calc(100% - 10px) center;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 30px;
  }

  &.file-hidden {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
  }

  &.error {
    border: 1px solid #cf2027;
  }
}

input[type="number"] {
  text-align: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type="search"] {
  /* width: calc(100% - 4px); */
  // 인풋에 나오는 기본 x버튼 제거---
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  // 인풋에 나오는 기본 x버튼 제거---
}

input:focus {
  border-color: var(--gray-100);
  color: var(--gray-100);
}

.hide-caret {
  caret-color: transparent !important;
}

/* placeholder */
input::-webkit-input-placeholder {
  color: var(--gray-50);
}

input:-ms-input-placeholder {
  color: var(--gray-50);
}

input::placeholder {
  color: var(--gray-50);
}
