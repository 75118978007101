
          @import "@/assets/scss/variables.scss";
          @import "@/assets/scss/style.scss";
        

.gap {
  max-width: 100%;
  position: relative;
  display: inline-block;
  min-width: 50px;
  box-sizing: border-box;
  // margin:0 4px;
}

.button {
  font-family: "Pretendard", Pretendard;
  display: block;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  background: yellow;
  box-sizing: border-box;
  cursor: pointer;
  text-indent: 0;
  // padding: 0px 6px;
  padding: 0;
  border-radius: 100px;
  border:0;
  background-color: #fff;
  color: #1e1e1e;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.2px;
  height: 100%;
  border:0;

  &.outline-none {
    border: none;
  }

  &.background-none {
    background: none;
  }

  &.primary {
    background-color: var(--primary);
    color: #fff;
  }

  &.red {
    color: #fff;
    border: 1px solid red;
    background-color: red;
  }

  &.black {
    color: #fff;
    border: 1px solid #000;
    background-color: #000;
  }

  &.gray-100 {
    background:var(--gray-100);
    color: #fff;
  }

  &.outline-black {
    color: #000;
    border: 1px solid #000;
  }

  &.big {
    height:64px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  &.small {
    padding: 0px 4px;
    line-height: 20px;
  }

  &.primary,
  &.red,
  &.black {
    &:disabled {
      color: #fff;
      background-color: #aaa !important;
      border-color: #999 !important;
      cursor: not-allowed;
    }
  }

  &:disabled {
    color:var(--disable-font);
    background: var(--disable-bg);
    cursor: not-allowed;
  }
}
