
          @import "@/assets/scss/variables.scss";
          @import "@/assets/scss/style.scss";
        

.inputbox input[type="checkbox"]:disabled + .checkbox {
  background-color: #ced5da;
  background-image: linear-gradient(#ced5da, #f1f3f5) !important;
}
.inputbox {
  /* user-select: none;
  margin: 0 0 0 4px;
  position: relative;
  padding: 1px;
  display: inline-block; */
  margin: auto;
  user-select: none;
  // margin: 0 4px;
  position: relative;
  border-radius: 5px;
  // padding: 1px;
  display: inline;
  font-size: 0;
  &:first-child {
    margin-left: 0;
  }
  padding:0;
  margin:0;
}

.checkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 20px;
  height: 20px;
  border:1px solid #9EA7AD;
  box-sizing: border-box;
  border-radius: 15px;
  background:#fff url('@/assets/icon/icon_checkbox_off.svg') no-repeat center center;
  // background-image: linear-gradient(#f0f0f0, #fff);
  // box-shadow: inset 0 1px 1px rgba(255,255,255,0.35), inset 0 -1px 1px rgba(0,0,0,0.35);
}
.checkbox svg {
  position: absolute;
  top: 3px;
  left: 3px;
  fill: none;
  stroke-linecap: square;
  stroke-linejoin: square;
  stroke: #fff;
  stroke-width: 2.5;
  stroke-dasharray: 17;
  stroke-dashoffset: 17;
  transform: scale(1.1);
}
.inputbox>label {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: auto !important;
  width: auto;
  line-height: initial !important;
  font-size: 0;
}
.inputbox input[type="checkbox"] {
  width: 2px;
  height: 2px;
  // margin: 0 4px;
  margin: 0;
  // padding: 4px 2px;
  padding: 9px;
  border: 0;
  outline: none;
  box-sizing: content-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  opacity: 0;
  // &:focus+span:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   border-radius: 5px;
  //   border-style: solid;
  //   border-color: var(--theme-primary-color);
  //   animation: shadow 0.1s ease-in-out forwards;
  // }
}
// @keyframes shadow {
//   to {
//     box-shadow: 0 0 6px var(--theme-primary-color);
//   }
// }
.inputbox input[type="checkbox"]:checked + .checkbox {
  // background-color: var(--theme-primary-color);
  // background-image: linear-gradient(var(--theme-primary-color), #2041fbbf);
  background:#F37324 url('@/assets/icon/icon_checkbox_on.svg') no-repeat center center;
  border-color:#F37324;
}
.inputbox input[type="checkbox"]:checked + .checkbox svg {
  // stroke-dashoffset: 0;
  // transition: all 0.15s ease;
}
